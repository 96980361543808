import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import axios from 'axios'
import { compact } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface HotelProps {
  image?: ImageProps
  logo?: string
  sort?: number
  URL?: string
}

export interface Props {
  languageCode: string
}

export const Hotels = memo(function Hotels({ languageCode }: Props) {
  const [list, setList] = useState<any>([])
  const [translations, setTranslations] = useState<any>([])

  const cmsURL = `https://mirahotels.cms.wa.qntdev.com`

  const getList = async () => {
    const h = await axios.get(`${cmsURL}/items/internation_hotel`)
    const t = await axios.get(`${cmsURL}/items/internation_hotel_translations`)

    setList(h.data.data)
    setTranslations(t.data.data)
  }

  useEffect(() => {
    getList()
  }, [])

  if (!list) {
    return null
  }

  return (
    <Container>
      <Title>{useVocabularyData('hotels-title', languageCode)}</Title>

      <Wrapper row wrap>
        {list
          .sort(
            (a: any, b: any) => (a.sort ? a.sort : 1) - (b.sort ? b.sort : 1),
          )
          .map((item: any, index: any) => {
            const trans = compact(
              translations
                .filter((t: any) => t.languages_code === languageCode)
                .filter((i: any) => i.internation_hotel_id === item.id),
            )[0] as any

            if (!trans) {
              return null
            }

            return (
              <Hotel
                key={index}
                href={trans.url}
                rel="noopener"
                target="_blank"
              >
                {item.image ? (
                  <LazyLoadComponent>
                    <Image src={`${cmsURL}/assets/${item.image}`} />
                  </LazyLoadComponent>
                ) : null}

                {item.logo ? (
                  <LazyLoadComponent>
                    <Logo
                      alt={translations.url}
                      src={`${cmsURL}/assets/${item.logo}`}
                      width="180"
                      height="60"
                    />
                  </LazyLoadComponent>
                ) : null}
              </Hotel>
            )
          })}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 0 11.944vw 8.6875rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 0 3.75rem 3.75rem;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
  &:before,
  &:after {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    flex: 1;
  }
  &:before {
    content: '';
    margin-right: 2.5rem;
  }
  &:after {
    margin-left: 2.5rem;
  }

  @media (max-width: 1023px) {
    display: block;
    &:before,
    &:after {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  margin: 1.875rem -1.625rem 0 0;

  @media (max-width: 1023px) {
    margin: 0.5rem 0 0;
  }
`

const Hotel = styled.a`
  width: calc(33.333% - 1.625rem);
  aspect-ratio: 1.93;
  overflow: hidden;
  margin-right: 1.625rem;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.6;
    }

    picture {
      img {
        transform: scale(1.1);
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
  }

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s ease-in-out;
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin: 1rem 0 0;
  }
`

const Logo = styled.img`
  width: auto;
  height: 3.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: 767px) {
    height: 2.5rem;
  }
`
