import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      {services.map((item, index) => (
        <Service key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-right: -0.25rem;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`
