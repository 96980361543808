import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  label?: string
  URL: string
}

export const Service = memo(function Service({ image, label, URL }: Props) {
  if (!URL) {
    return null
  }

  const buttonDOM = (
    <>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {label ? <Label>{label}</Label> : null}
    </>
  )

  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return externalURL ? (
    <ExtLink href={URL} rel="noopener" target="_blank">
      {buttonDOM}
    </ExtLink>
  ) : (
    <Anchor to={URL}>{buttonDOM}</Anchor>
  )
})

const Style = css`
  width: calc(50% - 0.125rem);
  background: ${theme.colors.variants.primaryLight};
  overflow: hidden;
  margin-right: 0.125rem;
  padding-bottom: 33.333%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
    span {
      &:after {
        right: 0;
        left: 0;
      }
    }
  }
  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-bottom: 50%;
  }
  &:nth-of-type(3) {
    margin-top: calc(-16.667% + 0.125rem);
  }
  &:nth-of-type(4),
  &:nth-of-type(5) {
    width: calc(25% - 0.125rem);
    margin-top: 0.125rem;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 0.125rem;
    margin-right: 0;
    padding-bottom: 72%;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      padding-bottom: 72%;
    }
    &:nth-of-type(3) {
      margin-top: 0.125rem;
    }
    &:nth-of-type(4),
    &:nth-of-type(5) {
      width: 100%;
    }
  }
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.5rem;
  padding-bottom: 0.375rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  z-index: 2;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 0;
    right: 50%;
    left: 50%;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.75rem;
  }

  @media (max-width: 767px) {
    right: 0;
    left: 0;
    padding: 0 2rem;
    transform: translate(0, -50%);
  }
`
